<template>
  <div>
    <div class="pa-0 ma-0 mt-3 pr-6">
      <hb-notification
        type="caution"
        :notDismissable="true"
      >
        Accounting Prerequisites.
        <v-row class="mt-1">
          <v-col cols="12" class="py-0 my-0"><hb-icon mdi-code="mdi-check" class="mr-2"></hb-icon>Complete Accounting Setup</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0 my-0"><hb-icon mdi-code="mdi-check" class="mr-2"></hb-icon>Configure Required Default COA</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0 my-0"><hb-icon mdi-code="mdi-check" class="mr-2"></hb-icon>Enable Accounting to Record Events</v-col>
        </v-row>
      </hb-notification>
    </div>
    <div class="mr-6 mt-4">
      <hb-data-table-header
        left-columns="6"
        right-columns="6"
      >
        <template v-slot:description>
          Download and read the guides before you setup or edit your accounting settings.
        </template>
        <template v-slot:actions>
          <hb-btn small color="secondary" @click="downloadGuide(GUIDES.CHART_OF_ACCOUNT)">Chart of Accounts Guide</hb-btn>
          <hb-btn small color="secondary" class="ml-3" @click="downloadGuide(GUIDES.JOURNAL_TEMPLATE)">Journal Template Guide</hb-btn>
        </template>
      </hb-data-table-header>
      <v-expansion-panels
        elevation="0"
        class="hb-expansion-panel"
        v-model="setupModal"
      >
        <hb-expansion-panel :top-margin="false" key="chart_of_account">
          <template v-slot:title> Chart of Accounts </template>
          <template v-slot:content>
            <chart-of-account
              class="mx-6 mb-6"
              :key="key_setup"
              v-if="setupModal == 0"
            ></chart-of-account>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel key="export_settings">
          <template v-slot:title>Export Settings</template>
          <template v-slot:content>
            <additional-settings
              :key="key_setup"
              v-if="setupModal == 1"
            ></additional-settings>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel key="setup_templates">
          <template v-slot:title>Templates</template>
          <template v-slot:content>
            <setup-accounting-templates
              :key="key_setup"
              v-if="setupModal == 2"
            ></setup-accounting-templates>
          </template>
        </hb-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script type="text/babel">
import GUIDES from "@/constants/guides.js";
import api from "../../../assets/api.js";
export default {
  name: "CorporateAccountingTemplate",
  data() {
    return {
      key_setup: 0,
      setupModal: [],
    };
  },
  components: {
    "chart-of-account": () => import("../Accounting/ChartOfAccount"),
    "additional-settings": () => import("../Accounting/AdditionalSettings"),
    "setup-accounting-templates": () => import("./SetupAccountingTemplates"),
  },
  created() {
    this.GUIDES = GUIDES;
  },
  methods: {
    async downloadGuide(name) {
      let res = await api.get(this, api.DOCUMENT + "guide/" + name);
      var buffer = Buffer.from(res.data);
      var blob = new Blob([buffer], { type: res.content_type });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = res.file_name;
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped></style>
